const commonData = {
  regions: {
    usa: 'USA',
    eu: 'EU',
    latam: 'LATAM',
  },
  latamCountries: ['COLOMBIA', 'PERU', 'ECUADOR', 'COSTA RICA', 'GUATEMALA', 'PANAMA', 'MEXICO'],
  euCountries: ['AUSTRIA', 'BELGIUM', 'CROATIA', 'CZECH REPUBLIC', 'DENMARK', 'ESTONIA', 'GERMANY', 'GREECE', 'HUNGARY', 'ICELAND', 'ITALY', 'LATVIA', 'LIECHTENSTEIN', 'LITHUANIA', 'NETHERLANDS', 'POLAND', 'SLOVENIA', 'SPAIN', 'SWEDEN', 'UNITED KINGDOM', 'SWITZERLAND'],
}

export default commonData
