import Axios from 'axios';
import { logout } from "../redux/actions/appActions";

const interceptor = (dispatch, history) => {
  Axios.interceptors.request.use(
    (conf) => {
      // you can add some information before send it.
      // conf.headers['Auth'] = 'some token'
      return conf;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  Axios.interceptors.response.use(
    (next) => {
      return Promise.resolve(next);
    },
    (error) => {      
      if (error.response.status === 401) {
        dispatch(logout(history));
      }
      return Promise.reject(error);
    }
  );
};

const interceptorClass = {
  interceptor
};

export default interceptorClass;