import { GET_LEDGER_START, GET_LEDGER_SUCCESS, GET_LEDGER_FAILURE } from './ledgerActionTypes';

import axios from 'axios';
import config from '../../config/env-url';

export const getLedger = (repId, token) => (dispatch) => {
  dispatch({ type: GET_LEDGER_START });
  return axios
    .get(`${config.LEDGERURL}/Credit/${repId}/CreditAccount/1/Credits`, { headers: { Authorization: `Bearer ${token}` } })
    .then((res) => {
      dispatch({ type: GET_LEDGER_SUCCESS, payload: res.data });
    })
    .catch((error) => {
      dispatch({ type: GET_LEDGER_FAILURE, payload: error });
    });
};
