import { GET_POINTS_START, GET_POINTS_SUCCESS, GET_POINTS_FAILURE, GET_AUTO_SHIP_DATA_START, GET_AUTO_SHIP_DATA_SUCCESS, GET_AUTO_SHIP_DATA_FAILURE, GET_PRODUCTS_DATA_START, GET_PRODUCTS_DATA_SUCCESS, GET_PRODUCTS_DATA_FAILURE, SUBMIT_CHECKOUT_GIFTS_SUCCESS, SUBMIT_CHECKOUT_GIFTS_START, SUBMIT_CHECKOUT_GIFTS_FAILURE, RESET_REDEMPTION_STATE, RESET_CART } from '../actions/redemptionActionTypes'
import axios from 'axios'
import config from '../../config/env-url'

export const fetchPointsData = (repId, token) => (dispatch) => {
  if (repId && token) {
    dispatch({ type: GET_POINTS_START })
    return axios
      .get(`${config.LEDGERURL}/StarPoint/${repId}/Balance`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        dispatch({ type: GET_POINTS_SUCCESS, payload: res.data })
      })
      .catch((error) => {
        dispatch({ type: GET_POINTS_FAILURE, payload: error })
      })
  } else {
    return new Promise((reject) => {
      reject('RepId and Token are required.')
    })
  }
}

export const fetchAutoShipData = (repId, token) => (dispatch) => {
  if (repId && token) {
    dispatch({ type: GET_AUTO_SHIP_DATA_START })
    return axios
      .get(`${config.LEDGERURL}/StarPoint/${repId}/AutoShipProfiles`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        dispatch({ type: GET_AUTO_SHIP_DATA_SUCCESS, payload: res.data })
      })

      .catch((error) => {
        dispatch({ type: GET_AUTO_SHIP_DATA_FAILURE, payload: error })
      })
  } else {
    return new Promise((reject) => {
      reject('RepId and Token are required.')
    })
  }
}

export const fetchProductsData = (token, country, repId) => (dispatch) => {
  if (country && token) {
    dispatch({ type: GET_PRODUCTS_DATA_START })
    return axios
      .get(`${config.LEDGERURL}/StarPoint/StarShipProducts/${country}?RepId=${repId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        dispatch({ type: GET_PRODUCTS_DATA_SUCCESS, payload: res.data })
      })
      .catch((error) => {
        dispatch({ type: GET_PRODUCTS_DATA_FAILURE, payload: error })
      })
  } else {
    return new Promise((reject) => {
      reject('Country and Token are required.')
    })
  }
}

export const fetchProductsDataVolumeFiltered = (token, country, minPoints, maxPoints) => (dispatch) => {
  if (country && token) {
    dispatch({ type: GET_PRODUCTS_DATA_START })
    return axios
      .get(`${config.LEDGERURL}/StarPoint/StarShipProducts/${country}?MinPoints=${minPoints || 0}&MaxPoints=${maxPoints || 10000}`, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        dispatch({ type: GET_PRODUCTS_DATA_SUCCESS, payload: res.data })
      })
      .catch((error) => {
        dispatch({ type: GET_PRODUCTS_DATA_FAILURE, payload: error })
      })
  } else {
    return new Promise((reject) => {
      reject('Country and Token are required.')
    })
  }
}

export const checkoutGifts = (repId, token, giftsData) => (dispatch) => {
  if (repId && token) {
    dispatch({ type: SUBMIT_CHECKOUT_GIFTS_START })
    return axios
      .post(`${config.LEDGERURL}/StarPoint/${repId}/Checkout`, giftsData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        dispatch({ type: SUBMIT_CHECKOUT_GIFTS_SUCCESS, payload: res.data })
        dispatch({ type: RESET_CART })
      })
      .catch((error) => {
        var message = ''
        const status = error.response.status
        if (status === 400) {
          message = 'errors.default-error-message'
        } else if (status === 500) {
          message = 'errors.default-error-message'
        } else {
          message = 'errors.default-error-message'
        }
        dispatch({ type: SUBMIT_CHECKOUT_GIFTS_FAILURE, payload: message })
      })
  } else {
    return new Promise((reject) => {
      reject('RepId and Token are required.')
    })
  }
}
