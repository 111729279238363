/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useRegionSelector from '../../hooks/useRegionSelector'
import { fetchAutoShipData, fetchPointsData, fetchProductsData } from '../../redux/actions/redemptionActions'
import { SET_PRODUCTS_FOR_AUTOSHIP, UPDATE_CURRENT_POINT_USED } from '../../redux/actions/redemptionActionTypes'
import styled from 'styled-components'
import Header from '../Header'
import { VolumeFilter } from './VolumeFilter'
import SortFilter from './SortFilter'
import { Container, Button } from 'react-bootstrap'
import RewardCard from './RewardCard'
import config from '../../config/env-url'
import { useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import SpinnerLoader from '../SpinnerLoader/SpinnerLoader'
import Cart from './Cart'
import SectionHeading from '../global-components/SectionHeading'
import Modal from '../global-components/Modal'

const ReedemFunnel = () => {
  const dispatch = useDispatch()
  const { isLATAM } = useRegionSelector()
  const intl = useIntl()

  const [calculatedPoints, setCalculatedPoints] = useState(null)
  const [reachedPointsLimit, setReachedPointsLimit] = useState(false)

  const [isCartEmpty, setIsCartEmpty] = useState(true)
  const { token, repId, userShipCountry } = useSelector((state) => state.app)
  const { availableAmount, starShipProducts, starShipCategories, selectedAutoshipProducts, cart, maxMonthlyRedemptionAmount, currentPointsUsed } = useSelector((state) => state.redemption)
  const [giftProducts, setGiftProducts] = useState([])
  const [filteredProducts, setFilteredProducts] = useState([])

  const [starShipSizes, setStarShipSizes] = useState([])
  const [selectedCategory, setSelectedCategory] = useState('All Products')
  const [selectedSize, setSelectedSize] = useState('Any')
  const [selectedSort, setSelectedSort] = useState('Lowest to Highest')
  const [selectedMinVolume, setSelectedMinVolume] = useState()
  const [selectedMaxVolume, setSelectedMaxVolume] = useState()

  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    dispatch(fetchPointsData(repId, token))
    dispatch(fetchAutoShipData(repId, token))
  }, [])

  useEffect(() => {
    filterAndSort()
  }, [selectedCategory, selectedMaxVolume, selectedSize, selectedMinVolume, selectedSort, giftProducts])

  useEffect(() => {}, [starShipSizes])

  useEffect(() => {
    setCalculatedPoints(availableAmount - currentPointsUsed)
  }, [availableAmount])

  useEffect(() => {}, [calculatedPoints])

  const handleUpdatePointsUsed = (process, amount) => {
    if (process === 'add') {
      const newAmount = currentPointsUsed + amount
      dispatch({ type: UPDATE_CURRENT_POINT_USED, payload: newAmount })
    } else {
      const newAmount = currentPointsUsed - amount
      dispatch({ type: UPDATE_CURRENT_POINT_USED, payload: newAmount })
    }
  }

  useEffect(() => {
    if (userShipCountry && Object.keys(cart).length === 0) {
      dispatch(fetchProductsData(token, userShipCountry, repId))
    }
  }, [userShipCountry])

  useEffect(() => {
    if (selectedAutoshipProducts.products.length > 0) {
      const mergedProducts = starShipProducts.map((item, i) => Object.assign({}, item, selectedAutoshipProducts.products[i]))
      setGiftProducts(mergedProducts)
    } else {
      const productsSetWithQuantity = starShipProducts.map((product) => ({
        ...product,
        quantity: product.quantity ? product.quanity : 0,
      }))
      setGiftProducts(productsSetWithQuantity)
    }
  }, [starShipProducts, selectedAutoshipProducts])

  let history = useHistory()

  const calculateSizes = (products) => {
    let availabileSizes = products.map((p) => p.size).filter((p) => p !== undefined && p !== null)
    availabileSizes = ['Any', ...new Set(availabileSizes)]
    if (!availabileSizes.includes(selectedSize)) {
      setSelectedSize('Any')
    }
    setStarShipSizes(availabileSizes)
  }

  const handleSort = (sortType) => {
    setSelectedSort(sortType)
  }

  const handleCategorySort = (sortType) => {
    setSelectedCategory(sortType)
  }

  const handleSizeSort = (size) => {
    setSelectedSize(size)
  }

  const handleFilterByVolume = (minVolume, maxVolume) => {
    setSelectedMaxVolume(maxVolume)
    setSelectedMinVolume(minVolume)
  }

  const filterAndSort = () => {
    let giftProductsData = [...starShipProducts]

    if (giftProductsData.length > 0) {
      if (selectedCategory !== 'All Products') {
        giftProductsData = giftProductsData.filter((product) => product.category === selectedCategory)
      }

      calculateSizes(giftProductsData)

      if (selectedSize !== 'Any') {
        giftProductsData = giftProductsData.filter((product) => product.size === selectedSize)
      }

      if (selectedMaxVolume) {
        giftProductsData = giftProductsData.filter((product) => product.points <= selectedMaxVolume)
      }

      if (selectedMinVolume) {
        giftProductsData = giftProductsData.filter((product) => product.points >= selectedMinVolume)
      }

      if (selectedSort === 'Highest To Lowest') {
        giftProductsData = giftProductsData.sort((a, b) => b.points - a.points)
      } else if (selectedSort === 'Lowest To Highest') {
        giftProductsData = giftProductsData.sort((a, b) => a.points - b.points)
      }
    }

    setFilteredProducts(giftProductsData)
  }

  const handleNext = () => {
    const addProducts = starShipProducts.filter((gift) => gift.quantity > 0)
    dispatch({
      type: SET_PRODUCTS_FOR_AUTOSHIP,
      payload: {
        totalQuantity: selectedAutoshipProducts.totalQuantity,
        giftsForAdd: addProducts,
      },
    })
    history.push('/order-details')
  }

  const toggleModal = (modalState) => {
    setShowModal(modalState)
  }

  const totalSelectedPoints = starShipProducts && starShipProducts.reduce((t, gift) => (gift.quantity > 0 ? t + gift.points * gift.quantity : t), 0)

  return (
    <OuterContainer>
      <Modal showModal={showModal} toggleModal={toggleModal} message={'dashboard.max-startpoint-modal'} />
      <HeaderContainer>
        <Header text={intl.formatMessage({ id: 'redeem.title' })} />
      </HeaderContainer>
      <StarpointsContainer>
        <div>
          <FormattedMessage id='redeem.points-available-msg' values={{ span: (chunks) => <span style={{ color: '#0F4B8F' }}>{chunks}</span>, selectedPoints: totalSelectedPoints, points: availableAmount }} />
        </div>
        <PointsMessage>
          <FormattedMessage id='redeem.max-points-message' values={{ maxAmount: maxMonthlyRedemptionAmount }} />
          {isLATAM && (
            <VatTaxStatement>
              *<FormattedMessage id='global-mesages.vat-msg' />*
            </VatTaxStatement>
          )}
        </PointsMessage>
      </StarpointsContainer>

      {Object.keys(cart).length > 0 && (
        <Cart
          data={{
            handleUpdatePointsUsed: handleUpdatePointsUsed,
            availablePoints: availableAmount,
            setReachedPointsLimit: setReachedPointsLimit,
            setIsCartEmpty: setIsCartEmpty,
            reachedPointsLimit: reachedPointsLimit,
            calculatedPoints: calculatedPoints,
            setCalculatedPoints: setCalculatedPoints,
            totalQuantity: totalSelectedPoints,
          }}
        />
      )}
      <SectionHeading title={'All Products'}></SectionHeading>
      <VolumeFilter handleFilterByVolume={handleFilterByVolume} />
      <SortFilter handleCategorySort={handleCategorySort} handleSortData={handleSort} handleSizeSort={handleSizeSort} categories={starShipCategories} sizes={starShipSizes} />
      <ScrollableContainer>
        {filteredProducts.length > 0 ? (
          filteredProducts.map((product, index) => {
            return (
              <RewardCard
                key={index}
                toggleModal={toggleModal}
                handleUpdatePointsUsed={handleUpdatePointsUsed}
                product={product}
                availablePoints={availableAmount}
                setReachedPointsLimit={setReachedPointsLimit}
                setIsCartEmpty={setIsCartEmpty}
                reachedPointsLimit={reachedPointsLimit}
                calculatedPoints={calculatedPoints}
                setCalculatedPoints={setCalculatedPoints}
                totalQuantity={totalSelectedPoints}
                productImg={product.largeImage !== '' ? `https://extranet.securefreedom.com/${config.FREEDOMURL}/Shopping/Images/${product.largeImage}` : ''}
              />
            )
          })
        ) : (
          <ErrorMessage>
            {' '}
            <SpinnerLoader width='35px' height='35px' /> <FormattedMessage id='redeem.no-matching-items-msg' />
          </ErrorMessage>
        )}
      </ScrollableContainer>
      <StyledButton onClick={handleNext} disabled={isCartEmpty}>
        <FormattedMessage id='redeem.next' />
      </StyledButton>
    </OuterContainer>
  )
}

const ErrorMessage = styled.div`
  padding: 40px;
  color: red;
`

const StyledButton = styled(Button)`
  background-color: #0f4b8f;
  margin-left: 25px;
  border-radius: 10px;
  height: 50px;
  width: 173px;
  white-space: nowrap;
  margin: 65px;

  flex-grow: none;

  @media (max-width: 768px) {
    width: 150px !important;
    font-size: 12px;
    margin: 20px 0 5px;
  }
`

const ScrollableContainer = styled.div`
  overflow-y: auto;
  height: 70vh;
`

const OuterContainer = styled(Container)`
  @media (max-width: 768px) {
    padding: 0;
  }
`

const HeaderContainer = styled.div`
  margin-bottom: 10px;
`

const StarpointsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 44px;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 22px;
  }
`

const PointsMessage = styled.p`
  font-size: 20px;
  font-weight: 700;
  width: 100%;
  text-align: center;
`

const VatTaxStatement = styled.p`
  font-weight: 800;
  font-size: 22px;
`

export default ReedemFunnel
