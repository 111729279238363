import { GET_POINTS_START, GET_POINTS_FAILURE, GET_POINTS_SUCCESS, GET_AUTO_SHIP_DATA_START, GET_AUTO_SHIP_DATA_SUCCESS, GET_AUTO_SHIP_DATA_FAILURE, GET_PRODUCTS_DATA_START, GET_PRODUCTS_DATA_SUCCESS, GET_PRODUCTS_DATA_FAILURE, SET_PRODUCTS_FOR_AUTOSHIP, SUBMIT_CHECKOUT_GIFTS_START, SUBMIT_CHECKOUT_GIFTS_SUCCESS, SUBMIT_CHECKOUT_GIFTS_FAILURE, UPDATE_CART, UPDATE_POINTS_VALID, RESET_REDEMPTION_STATE, UPDATE_CURRENT_POINT_USED, RESET_CART } from '../actions/redemptionActionTypes';

export const initalState = {
  fetching: false,
  error: null,
  creditAccountId: null,
  availableAmount: null,
  lifetimeAmount: null,
  balance: null,
  balanceRealTime: null,
  maxMonthlyRedemptionAmount: null,
  monthlyRedemptionAmount: null,
  totalVolume: null,
  autoShipProfiles: [],
  starShipProducts: [],
  starShipCategories: [],
  selectedAutoshipProducts: {
    totalQuantity: 0,
    products: [],
  },
  cart: {},
  pointsValid: false,
  currentPointsUsed: 0,
  orderSuccessful: false,
};

export const redemptionReducer = (state = initalState, action) => {
  switch (action.type) {
    case GET_POINTS_START:
      return {
        ...state,
        fetching: true,
        error: false,
      };
    case GET_POINTS_SUCCESS:
      return {
        ...state,
        creditAccountId: action.payload.creditAccountId,
        availableAmount: action.payload.availableAmount,
        lifetimeAmount: action.payload.lifetimeAmount,
        balance: action.payload.balance,
        balanceRealTime: action.payload.balanceRealTime,
        maxMonthlyRedemptionAmount: action.payload.maxMonthlyRedemptionAmount,
        monthlyRedemptionAmount: action.payload.monthlyRedemptionAmount,
      };
    case GET_POINTS_FAILURE:
      return {
        ...state,
        fetching: false,
        error: true,
      };
    case GET_AUTO_SHIP_DATA_START:
      return {
        ...state,
        fetching: true,
        error: false,
      };

    case GET_AUTO_SHIP_DATA_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: false,
        autoShipProfiles: action.payload.autoShipProfiles,
        totalVolume: action.payload.totalVolume,
      };
    case GET_AUTO_SHIP_DATA_FAILURE:
      return {
        ...state,
        fetching: false,
        error: true,
      };
    case GET_PRODUCTS_DATA_START:
      return {
        ...state,
        fetching: true,
        error: false,
      };
    case GET_PRODUCTS_DATA_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: false,
        starShipProducts: action.payload.products,
        starShipCategories: action.payload.categories,
      };
    case GET_PRODUCTS_DATA_FAILURE:
      return {
        ...state,
        fetching: false,
        error: true,
      };
    case SET_PRODUCTS_FOR_AUTOSHIP:
      // if product doesn't exists add product passed in
      //if product exists increase by the number passed in
      //if new quantity less than or equals to zero then remove

      return {
        ...state,
        selectedAutoshipProducts: {
          totalQuantity: action.payload.totalQuantity,
          products: [...action.payload.giftsForAdd],
        },
      };
    case SUBMIT_CHECKOUT_GIFTS_START:
      return {
        ...state,
        fetching: true,
        error: null,
        orderSuccessful: false,
      };
    case SUBMIT_CHECKOUT_GIFTS_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
        orderSuccessful: true,
        selectedAutoshipProducts: {
          totalQuantity: 0,
          products: [],
        },
      };
    case SUBMIT_CHECKOUT_GIFTS_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.payload,
        orderSuccessful: false,
      };
    case UPDATE_CART:
      return {
        ...state,
        cart: { ...action.payload },
      };

    case UPDATE_POINTS_VALID:
      return {
        ...state,
        pointsValid: action.payload,
      };

    case UPDATE_CURRENT_POINT_USED:
      return {
        ...state,
        currentPointsUsed: action.payload,
      };

    case RESET_REDEMPTION_STATE:
      return {
        ...initalState,
        cart: {},
      };

    case RESET_CART:
      return {
        ...state,
        cart: {},
        currentPointsUsed: 0,
        starShipProducts: [
          ...state.starShipProducts.map((product) => {
            if (product.hasOwnProperty('quantity')) delete product['quantity'];
            return product;
          }),
        ],
      };

    default:
      return state;
  }
};
