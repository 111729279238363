import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { faUser } from '@fortawesome/fontawesome-free-solid';
import config from '../../config/env-url';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { device } from '../../config/device-sizes';

const Profile = () => {
  const { user } = useSelector((state) => state.app);

  return (
    <Container>
      <Pic>{user && user.pictureFilename ? <ProfileImageIcon profileImage={user.pictureFilename} /> : <FontAwesomeIcon icon={faUser} size="xs" color="black" style={{ marginBottom: '5px' }} />}</Pic>
    </Container>
  );
};

const Container = styled.div`
  border-radius: 5px;
  font-family: Roboto, sans-serif;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin: 0 3%;

  @media (max-width: 992px) {
    margin: 3%;
  }
`;

const ProfileImageIcon = styled.div`
  height: 45px;
  width: 45px;
  background-image: url('${(props) => (props.profileImage ? `https://extranet.securefreedom.com/${config.FREEDOMURL}/personal/personalimages/` + props.profileImage : `/assets/images/person_palceholder_img.png`)}');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  margin-top: 5%;
`;

const Pic = styled.div`
  border-radius: 50%;
  height: 5px;
  background-color: #cccccc;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 48px;
  color: #ffffff;

  @media ${device.tablet} {
    display: none;
  }
`;

export default Profile;
