import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { subNavLinks } from './LinkData';

const DesktopLinks = () => {
  const { isLoggedIn } = useSelector((state) => state.app);
  const isMobile = useMediaQuery({ query: '(max-width: 991px)' });

  return (
    <>
      {isLoggedIn && !isMobile && (
        <LinksWrapper>
          <LinksList>
            {subNavLinks.map((link, index) => {
              return (
                <LinkListItem key={index}>
                  <NavLink style={{ color: '#2B2B2B', fontSize: '31px' }} to={link.link} activeClassName="selectedLink">
                    <FormattedMessage id={link.tanslateId}></FormattedMessage>
                  </NavLink>
                </LinkListItem>
              );
            })}
          </LinksList>
        </LinksWrapper>
      )}
    </>
  );
};

export default DesktopLinks;

const LinksWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

const LinksList = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  max-width: 1140px;
`;

const LinkListItem = styled.li`
  margin: 0 3%;
`;
