import React from 'react';
import styled from 'styled-components';

const SectionHeading = ({ title, children }) => {
  return (
    <TableHeading>
      {title} {children}
    </TableHeading>
  );
};

export default SectionHeading;

const TableHeading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 85px;
  background-color: #0f4b8f;
  color: #fff;
  text-transform: uppercase;
  font-size: 50px;
  font-family: proxima-nova, sans-serif;
  font-weight: 700;
  font-style: normal;
  position: relative;

  @media (max-width: 995px) {
    font-size: 5.5vw;
  }
  @media (max-width: 595px) {
    height: 50px;
    font-size: 7vw;
  }
`;
