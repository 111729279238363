export const GET_POINTS_START = '[STARPOINTS] GET POINTS START';
export const GET_POINTS_SUCCESS = '[STARPOINTS] GET POINTS SUCCESS';
export const GET_POINTS_FAILURE = '[STARPOINTS] GET POINTS FAILURE';

export const GET_AUTO_SHIP_DATA_START = '[STARPOINTS] GET AUTO SHIP DATA START';
export const GET_AUTO_SHIP_DATA_SUCCESS = '[STARPOINTS] GET AUTO SHIP DATA SUCCESS';
export const GET_AUTO_SHIP_DATA_FAILURE = '[STARPOINTS] GET AUTO SHIP DATA FAILURE';

export const GET_PRODUCTS_DATA_START = '[STARPOINTS] GET_PRODUCTS_DATA START';
export const GET_PRODUCTS_DATA_SUCCESS = '[STARPOINTS] GET_PRODUCTS_DATA SUCCESS';
export const GET_PRODUCTS_DATA_FAILURE = '[STARPOINTS] GET_PRODUCTS_DATA FAILURE';

export const SUBMIT_CHECKOUT_GIFTS_START = '[STARPOINTS] SUBMIT CHECKOUT GIFTS START';
export const SUBMIT_CHECKOUT_GIFTS_SUCCESS = '[STARPOINTS] SUBMIT CHECKOUT GIFTS SUCCESS';
export const SUBMIT_CHECKOUT_GIFTS_FAILURE = '[STARPOINTS] SUBMIT CHECKOUT GIFTS FAILURE';

export const SET_PRODUCTS_FOR_AUTOSHIP = '[STARPOINTS] SET_PRODUCTS_FOR_AUTOSHIP';

export const UPDATE_POINTS_VALID = '[STARPOINTS] SET POINTS VALID';

export const UPDATE_CART = '[STARPOINTS] UPDATE CART';

export const RESET_REDEMPTION_STATE = '[STARPOINTS] RESET REDEMPTION STATE';

export const RESET_CART = '[STARPOINTS] RESET CART STATE';

export const UPDATE_CURRENT_POINT_USED = '[STARPOINTS] UPDATE CURRENT POINTS USED FOR ORDER';
