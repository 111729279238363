class URLHelper {
  handleGetURLQueryValue = (queryKey) => {
    var keyExists = null;
    var found = false;
    const urlQuery = window.location.search.substr(1);
    var vars = urlQuery.split('&');
    vars.forEach((query, index) => {
      var queryPair = vars[index].split('=');
      const keyFound = queryPair.find(key => key === queryKey)
        keyFound ? keyExists = queryPair[1] : found = false
      })  
      return  keyExists ? keyExists : found;
    }
}

export default URLHelper;