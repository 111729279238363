import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import starImg from "../../assets/StarClub@2x.png";
import { Link } from "react-router-dom";
import {FormattedMessage } from "react-intl";

const OrderInfo = ({ reciept, orderPlaced }) => {

  const { selectedAutoshipProducts, availableAmount } = useSelector(
    (state) => state.redemption
  );

  const totalPoints = selectedAutoshipProducts.products.reduce((s, a) => {
    return (s += a.points * a.quantity);
  }, 0);

  return (
    <OrderInfoContainer>
      <table>
        <thead>
        <tr>
          <th><FormattedMessage id="order-info.your-order-today" />:</th>
          <th><FormattedMessage id="order-info.star-points" /></th>
        </tr>  
        </thead>
        <tbody>
          {!orderPlaced && selectedAutoshipProducts.products && selectedAutoshipProducts.products.length > 0 && selectedAutoshipProducts.products.map((product, index) => (
            <tr key={index}>
              <td>
                {product.quantity} {product.description}      
              </td>
              <td>
                {product.points * product.quantity}
              </td>
            </tr>
          ))}
          {orderPlaced && reciept.length > 0 && reciept.map((product, index) => (
            <tr key={index}>
              <td>
                {product.quantity} {product.description}      
              </td>
              <td>
                {product.points * product.quantity}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {!orderPlaced && <Link className="returnLink" to="/redeem"><FormattedMessage id={selectedAutoshipProducts.products.length > 1 ? 'order-info.change-selections' : 'order-info.change-selection'} /></Link>}
      <table style={{marginTop: '40px'}}>
        <thead>
          <tr>
            <th><FormattedMessage id="order-info.total-points-msg" />:</th>
            <th>
              <img alt="" src={starImg} style={{ width: "35px", height: "35px" }} /> {!orderPlaced ? totalPoints : (reciept && reciept.length > 0 ? reciept.reduce((s, a) => (s += a.points * a.quantity), 0) : 0)}
            </th>
          </tr>
        </thead>
          <tbody>
            <tr>
              <td><FormattedMessage id="order-info.balance-msg" />:</td>
              <td>{availableAmount - (!orderPlaced ? totalPoints : (reciept && reciept.length > 0 ? reciept.reduce((s, a) => (s += a.points * a.quantity), 0) : 0))}</td>
            </tr>
          </tbody>
      </table>
    </OrderInfoContainer>
  );
};

const OrderInfoContainer = styled.div`
  text-align: left;

  table {
    width: 100%;
    text-align: left;

    th {
      font-size: min(28px, 5vw);
      padding: 5px 0;
    }

    td {
      font-size: min(22px, 4vw);
      padding: 5px 0;
    }

    td:first-child {
      padding-left: 20px;
    }

    td:last-child, th:last-child {
      text-align: right;
    }
  }

  .returnLink {
    display: block;
    font-size: min(26px, 5vw);
    margin: 10px 0 40px;
    text-decoration: underline;
  }
`;

export default OrderInfo;
