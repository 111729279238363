/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react';
import { Container, Row, Col, FormControl, Button } from 'react-bootstrap/';
import { InfoCircleFill } from 'react-bootstrap-icons';
import Star from '../../assets/StarClub@2x.png';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { getLedger } from '../../redux/actions/ledgerActions';
import { fetchPointsData } from '../../redux/actions/redemptionActions';
import ReactMoment from 'react-moment';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import SpinnerLoader from '../SpinnerLoader/SpinnerLoader';

const Ledger = () => {
  const dispatch = useDispatch();
  const { pointsInfo } = useSelector((state) => state.ledger);
  const { availableAmount, lifetimeAmount } = useSelector((state) => state.redemption);
  const { repId, token } = useSelector((state) => state.app);
  const [minDate, setMinDate] = useState('');
  const [maxDate, setMaxDate] = useState('');
  const [showAllActivity, setShowAllActivity] = useState('');
  const [localPointsInfo, setLocalPointsInfo] = useState([]);
  const moment = extendMoment(Moment);

  useEffect(() => {
    dispatch(getLedger(repId, token));
    dispatch(fetchPointsData(repId, token));
  }, []);

  useEffect(() => {
    setLocalPointsInfo(pointsInfo);
  }, [pointsInfo]);

  function getRange(startDate, endDate) {
    const fromDate = moment(startDate);
    const toDate = moment(endDate);
    const diff = toDate.diff(fromDate, 'days');
    const range = [];
    for (let i = 0; i <= diff; i++) {
      range.push(moment(moment(startDate).add(i, 'days')).format('YYYY-MM-DDTHH:mm:ss'));
    }
    return range;
  }

  const filterLocalPointInfo = (startDate, endDate) => {
    const filteredRange = getRange(startDate, endDate);
    const newFilteredDate = [];
    pointsInfo.forEach((pointData) => {
      filteredRange.forEach((filteredData) => {
        if (filteredData === pointData.activeDate) {
          newFilteredDate.push(pointData);
        }
      });
    });
    setLocalPointsInfo(newFilteredDate);
    setShowAllActivity(newFilteredDate);
  };

  const resetDateFilter = () => {
    dispatch(getLedger(repId, token));
  };

  return (
    <div>
      <Container className="my-5">
        <Row className="mt-5 text-left">
          {/*This is for the star points snapshot*/}
          <Col xl={6} lg={6} md={6} sm={6} xs={6}>
            <PointBalanceText>
              <FormattedMessage id="ledger.starpoints-available" />
            </PointBalanceText>
            &nbsp; &nbsp;
            <PointBalanceNumber>{availableAmount}</PointBalanceNumber>&nbsp;
            <StarIcon src={Star} className="star-icon" width="60px" alt="star icon" />
          </Col>
          <Col xl={6} lg={6} md={6} sm={6} xs={6}>
            <PointBalanceText>
              <FormattedMessage id="ledger.lifetime-earned" />
            </PointBalanceText>
            &nbsp; &nbsp;
            <PointBalanceNumber>{lifetimeAmount}</PointBalanceNumber>&nbsp;
            <StarIcon src={Star} className="star-icon" width="60px" alt="star icon" />
          </Col>
        </Row>
        <Row className="mt-5 text-md-left align-items-center">
          {/*This is for "What products qualify section"*/}
          <Col xl={6} lg={6} md={8} sm={12} xs={12}>
            <p>
              <FormattedMessage id="ledger.what-products-msg" /> &nbsp;
              <a href="https://resources.zilis.com/resources/starclub/" target="_blank" rel="noreferrer">
                <InfoCircleFill style={{ color: '#AEAEAE' }} />
              </a>
            </p>
          </Col>
          <Col xs={3}></Col>
          <Col xs={3}></Col>
        </Row>
        <Row>
          <SolidLine></SolidLine>
        </Row>
        <Row>
          {/*Date Range */}
          <Col xs={6}>
            <InputContainer>
              <DatePicker
                aria-label="Small"
                aria-describedby="inputGroup-sizing-sm"
                width="25%"
                type="date"
                onChange={(e) => {
                  return setMinDate(e.target.value);
                }}
              />
              <span style={{ padding: '8px' }}>
                <FormattedMessage id="form-labels.to" />
              </span>
              <DatePicker aria-label="Small" aria-describedby="inputGroup-sizing-sm" width="25%" type="date" onChange={(e) => setMaxDate(e.target.value)} />
              <StyledButton onClick={() => filterLocalPointInfo(minDate, maxDate)}>
                <FormattedMessage id="form-labels.go" />
              </StyledButton>
              {showAllActivity && (
                <AllHistoryButton onClick={resetDateFilter}>
                  <FormattedMessage id="ledger.see-all-activity" />
                </AllHistoryButton>
              )}
            </InputContainer>
          </Col>
        </Row>
        <Row className="mt-2 ml-2 py-3 points-row text-md-left align-items-center">
          <Col xs={4}>
            <HeaderText>
              <FormattedMessage id="ledger.activity" />
            </HeaderText>
          </Col>
          <Col xs={4}></Col>
          <Col xs={2}>
            <HeaderText>
              <FormattedMessage id="ledger.value" />
            </HeaderText>
          </Col>
          <Col xs={2}>
            <HeaderText>
              <FormattedMessage id="ledger.running-total" />
            </HeaderText>
          </Col>
        </Row>
        {/*This is for the import of the points section*/}
        {localPointsInfo && localPointsInfo.length >= 1 ? (
          localPointsInfo.map((points, index) => (
            <PointsInfoTable key={index}>
              <Row className="mt-2 ml-2 py-3 points-row text-md-left align-items-center">
                <Col xs={4}>
                  <TableText sm={12} xs={12}>
                    <ReactMoment parse="YYYY-MM-DDTHH:mm:ss" format="MM/DD/YYYY">
                      {points.activeDate}
                    </ReactMoment>
                  </TableText>
                  <TableText>{points.description}</TableText>
                </Col>
                <Col xs={4}></Col>
                <Col xs={2}>
                  <PointsUsed style={{ color: points.amount < 0 ? 'red' : '#0C4C8F' }}>{points.amount}</PointsUsed>
                </Col>
                <Col xs={2}>
                  <RunningTotal>{points.runningTotal}</RunningTotal>
                </Col>
              </Row>
            </PointsInfoTable>
          ))
        ) : (
          <PointsInfoTable>
            <Row>
              <Col>
                <SpinnerLoader width="35px" height="35px" />
                <p style={{ margin: '1rem 0' }}>
                  {' '}
                  <FormattedMessage id="ledger.no-activity-msg" />
                </p>
              </Col>
            </Row>
          </PointsInfoTable>
        )}
      </Container>
    </div>
  );
};

export default Ledger;

const ReturnLink = styled.div`
  position: relative;

  a {
    position: absolute;
    right: 0;
    top: -20px;
    display: inline-block;
    margin-right: 10px;
  }
`;

const PointBalanceText = styled.h4`
  display: inline-block;
  vertical-align: middle;
  letter-spacing: 1.5px;

  @media (max-width: 768px) {
    .points-text {
      font-size: 15px !important;
      display: inline-block;
      vertical-align: middle;
    }
  }
`;

const PointBalanceNumber = styled.h1`
  display: inline-block;
  vertical-align: middle;
  letter-spacing: 1.5px;

  @media (max-width: 768px) {
    font-size: 25px !important;
    display: inline-block;
    vertical-align: middle;
  }
`;

const SolidLine = styled.div`
  border-top: 40px;
  border: solid;
  color: #febc1b;
  width: 100%;
`;

const PointsUsed = styled.h5`
  color: #0c4c8f;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

const PointsInfoTable = styled.div`
  background-color: #f7f7f8;

  &:nth-child(2n + 1) {
    background-color: #f4fafe;
  }
`;

const StarIcon = styled.img`
  position: relative;
  margin-bottom: 20px;
`;

const HeaderText = styled.h5`
  color: #0c4c8f;
`;

const TableText = styled.h5`
  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const RunningTotal = styled.h5`
  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

const InputContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  margin-top: 10px;
`;

const DatePicker = styled(FormControl)`
  width: 37%;
  border-radius: 10px;
  @media (max-width: 768px) {
    width: 85%;
  }
`;

const StyledButton = styled(Button)`
  background-color: #0f4b8f;
  text-transform: uppercase;
  margin-left: 25px;
`;
const AllHistoryButton = styled(Button)`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  padding-left: 10px;
  padding-top: 5px;

  &:hover {
    background: transparent;
    box-shadow: 0px 0px 0px transparent;
    border: 0px solid transparent;
    color: inherit;
    text-decoration: underline;
  }

  &:active {
    outline: none !important;
    background: transparent !important;
    box-shadow: 0px 0px 0px transparent !important;
    border: 0px solid transparent !important;
    color: inherit !important;
    text-decoration: underline;
  }

  &:focus {
    border: none;
    outline: none;
    background: transparent;
    box-shadow: 0px 0px 0px transparent;
    border: 0px solid transparent;
    color: inherit;
    text-decoration: underline;
  }
`;
