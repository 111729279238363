import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import RewardCard from './RewardCard';
import styled from 'styled-components';
import config from '../../config/env-url';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/fontawesome-free-solid';
import SectionHeading from '../global-components/SectionHeading';

const Cart = (props) => {
  const { cart, starShipProducts } = useSelector((state) => state.redemption);
  const [cartItems, setCartItems] = useState([]);

  useEffect(() => {
    updateCartView();
  }, [cart]);

  const updateCartView = () => {
    const selectedItemsKeys = Object.keys(cart);
    const selectedItems = selectedItemsKeys.map((sku) => {
      return starShipProducts.find((product) => {
        if (product.productSku === sku) return product;
      });
    });
    setCartItems(selectedItems);
  };

  return (
    <>
      <SectionHeading title={'Cart'}>
        <CartQuantity>
          <FontAwesomeIcon style={{ fontSize: '30px', color: '#fff' }} icon={faShoppingCart} />
          &nbsp;
          {Object.values(cart).reduce((accum, curr) => accum + curr)}
        </CartQuantity>
      </SectionHeading>

      <CarWrapper>
        {cartItems.length > 0 &&
          cartItems.map((item, index) => {
            return (
              <RewardCard
                key={index}
                product={item}
                handleUpdatePointsUsed={props.data.handleUpdatePointsUsed}
                availablePoints={props.data.availablePoints}
                setReachedPointsLimit={props.data.setReachedPointsLimit}
                setIsCartEmpty={props.data.setIsCartEmpty}
                setGiftProducts={props.data.setGiftProducts}
                giftProducts={props.data.giftProducts}
                reachedPointsLimit={props.data.reachedPointsLimit}
                calculatedPoints={props.data.calculatedPoints}
                setCalculatedPoints={props.data.setCalculatedPoints}
                totalQuantity={props.data.totalQuantity}
                productImg={item.largeImage !== '' ? `https://extranet.securefreedom.com/${config.FREEDOMURL}/Shopping/Images/${item.largeImage}` : ''}
              />
            );
          })}
      </CarWrapper>
    </>
  );
};

export default Cart;

const CarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-height: 25vh;
  overflow-y: auto;
  overflow-x: hidden;
  border: 5px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 2%;
  padding: 2%;
`;

const CartQuantity = styled.div`
  position: absolute;
  right: 30px;
  font-size: 30px;
`;
