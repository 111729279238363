export const APP_LOAD_SUCCESS = "[APP] APPLICATION LOADED SUCCESS";
export const APP_LOGIN_START = "[APP] APP LOGIN START";
export const APP_LOGIN_SUCCESS = "[APP] APP LOGIN SUCCESS";
export const APP_LOGIN_ERROR = "[APP] APP LOGIN ERROR";
export const SSO_LOGIN_START = "[APP] SSO LOGIN START";
export const SSO_LOGIN_SUCCESS = "[APP] SSO LOGIN SUCCESSFUL";
export const SSO_LOGIN_FAILURE = "[APP] SSO LOGIN FAILURE";
export const GET_SSO_TOKEN_START = "[APP] GET SSO TOKEN";
export const GET_SSO_TOKEN_SUCCESS = "[APP] GET SSO TOKEN SUCCESS";
export const GET_SSO_TOKEN_FAILURE = "[APP] GET SSO TOKEN FAILURE";
export const GET_USER_DATA_START = "[APP] GET USER DATA START";
export const GET_USER_DATA_SUCCESS = "[APP] GET USER DATA SUCCESS";
export const GET_USER_DATA_FAILURE = "[APP] GET USER DATA FAILURE";
export const LOGOUT = "[APP] LOG_OUT";
export const RESET_APP_STATE = "[APP] RESET APP STATE";
export const CHANGE_LOCALE = "[APP] CHANGE LOCALE";
export const UPDATE_USER_REGION = "[APP] UPDATE USER REGION"
