import React from "react";
import styled from "styled-components";
import moment from "moment";
import { FormattedMessage, FormattedDate } from 'react-intl';

const StarpointScale = ({ bv }) => {

  return (
    <div>
      <Title><FormattedMessage id='starpoint-scale.gift-level-msg' values={{date: <FormattedDate value={moment()} month="long" />}} /></Title>
      {bv < 100 && <div><FormattedMessage id='starpoint-scale.add-items-msg' /></div>}
      <StarpointScaleLine>
        <BVLevel className={bv >= 100 && bv < 200 ? "active" : ""}>
          <div className="BVHeader"><FormattedMessage id='starpoint-scale.current-starpoint-level' /></div>
          100 - 199 BV
          <a target="_blank" href={'https://resources.zilis.com/resources/starclub-loyalty-program/?autologin_code=vKI6JTZPA88l2xusgD9bcP0ntG8rKZk3'} rel="noreferrer"><FormattedMessage id='starpoint-scale.this-months-gifts.msg' /></a>
        </BVLevel>
        <BVLevel className={bv >= 200 && bv < 300 ? "active" : ""}>
          <div className="BVHeader"><FormattedMessage id='starpoint-scale.current-starpoint-level' /></div>
          200 - 299 BV
          <a target="_blank" href={'https://resources.zilis.com/resources/starclub-loyalty-program/?autologin_code=vKI6JTZPA88l2xusgD9bcP0ntG8rKZk3'} rel="noreferrer"><FormattedMessage id='starpoint-scale.this-months-gifts.msg' /></a>
        </BVLevel>
        <BVLevel className={bv >= 300 && bv < 400 ? "active" : ""}>
          <div className="BVHeader"><FormattedMessage id='starpoint-scale.current-starpoint-level' /></div>
          300 - 399 BV
          <a target="_blank" href={'https://resources.zilis.com/resources/starclub-loyalty-program/?autologin_code=vKI6JTZPA88l2xusgD9bcP0ntG8rKZk3'} rel="noreferrer"><FormattedMessage id='starpoint-scale.this-months-gifts.msg' /></a>
        </BVLevel>
        <BVLevel className={bv >= 400 ? "active" : ""}>
          <div className="BVHeader"><FormattedMessage id='starpoint-scale.current-starpoint-level' /></div>
          400 BV & ABOVE
          <a target="_blank" href={'https://resources.zilis.com/resources/starclub-loyalty-program/?autologin_code=vKI6JTZPA88l2xusgD9bcP0ntG8rKZk3'} rel="noreferrer"><FormattedMessage id='starpoint-scale.this-months-gifts.msg' /></a>
        </BVLevel>
      </StarpointScaleLine>
      {bv < 100 && <a target="_blank" href={'https://resources.zilis.com/resources/starclub-loyalty-program/?autologin_code=vKI6JTZPA88l2xusgD9bcP0ntG8rKZk3'} rel="noreferrer"><FormattedMessage id='starpoint-scale.this-months-gifts.msg' /></a>}
    </div>
  );
};


const Title = styled.div`
  font-size: 30px;
  text-align: left;
  font-weight: bold;
  margin-top: 60px;
`;

const BVLevel = styled.div`
  font-size: 24px;
  color: #0F4B8F;
  text-transform: uppercase;
  text-align: center;
  display: inline-block;
  font-weight: bold;
  padding: 5px;
  background: #f4fafe 0% 0% no-repeat padding-box;
  padding: 5px 20px;
  flex:1;

  a {
    display: none;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
    text-transform: none;
  }

  .BVHeader {
    display: none;
    font-size: 18px;
    text-align: center;
    color: #ffffff;
    text-transform: none;
  }

  &.active {
    height: auto;
    background-color: #FEBC1B;

    a {
      display: block;
    }
    
    .BVHeader {
      display: block;
    }
  }
`;

const StarpointScaleLine = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  width: 100%;
  
`;

export default StarpointScale;
