import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { useDispatch, useSelector } from 'react-redux'
import useRegionSelector from '../../hooks/useRegionSelector'
import { checkoutGifts } from '../../redux/actions/redemptionActions'
import OrderInfo from './OrderInfo'
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl'

import { Container as BContainer, Row, Col, Container, Button } from 'react-bootstrap'
import Header from '../Header'
import AutoshipCards from './AutoshipCards'
import SpinnerLoader from '../SpinnerLoader/SpinnerLoader'

const OrderDetails = () => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const { isLATAM } = useRegionSelector()
  const { token, repId, user } = useSelector((state) => state.app)
  const { autoShipProfiles, selectedAutoshipProducts, creditAccountId, orderSuccessful, fetching, error } = useSelector((state) => state.redemption)
  const [orderPlaced, setOrderPlaced] = useState(false)
  const [reciept, setReciept] = useState([])

  useEffect(() => {
    setOrderPlaced(orderSuccessful)
  }, [orderSuccessful])

  const [selectedAutoShipInfo, setSelectedAutoShipInfo] = useState({
    shipTo: null,
    autoShipDateParsed: null,
    profileId: null,
    totalQuantity: null,
  })

  const handlePlaceOrder = () => {
    if (selectedAutoshipProducts.products.length > 0 && selectedAutoShipInfo.autoShipDateParsed) {
      const orderDetails = {
        autoShipProfileId: selectedAutoShipInfo.profileId,
        shipCountry: user.shipCountry,
        creditAccountId: creditAccountId,
        products: selectedAutoshipProducts.products.map((product) => ({
          inventoryID: product.inventoryId,
          quantity: product.quantity,
          productSku: product.productSku,
        })),
      }
      setReciept(selectedAutoshipProducts.products.map((p) => ({ ...p })))
      dispatch(checkoutGifts(repId, token, orderDetails))
    }
  }

  return (
    <StyledContainer>
      <Header text={intl.formatMessage({ id: orderPlaced ? 'order-details.order-confirmation' : 'order-details.order-details' })} />
      {!orderPlaced && (
        <h3>
          <FormattedMessage id='order-details.select-autoship-msg' />
        </h3>
      )}
      <Container fluid style={{ marginBottom: '30px' }}>
        <Row>
          {!orderPlaced ? (
            autoShipProfiles.map((profile, index) => (
              <Col md={6} sm={12} style={{ marginTop: '10px', marginBottom: '10px' }} key={index}>
                <AutoshipCards setSelectedAutoShipInfo={setSelectedAutoShipInfo} key={profile.profileId} runDate={profile.nextShipDate} shipTo={profile.streetAddress} quantity={profile.totalItems} profileId={profile.profileId} />
              </Col>
            ))
          ) : (
            <p style={{ margin: '40px auto 0', textAlign: 'center', maxWidth: '600px' }}>
              <FormattedMessage
                id='order-details.thank-you'
                values={{
                  date: (
                    <span style={{ fontSize: '30px', display: 'block' }}>
                      <FormattedDate value={selectedAutoShipInfo.autoShipDateParsed} />
                    </span>
                  ),
                }}
              />
            </p>
          )}
        </Row>
      </Container>
      <Row>
        <Col>
          <OrderInfo reciept={reciept} orderPlaced={orderPlaced} />
          {!orderPlaced ? (
            <SubmitButtonWrapper>
              <Button onClick={handlePlaceOrder} disabled={!selectedAutoShipInfo.autoShipDateParsed || fetching}>
                <FormattedMessage id='order-details.place-order' />
              </Button>
              {fetching && (
                <div id='async-loader'>
                  <SpinnerLoader />
                </div>
              )}
            </SubmitButtonWrapper>
          ) : (
            <Button target='_blank' rel='noreferrer' href={`https://tools.securefreedom.com/Zilis/`}>
              <FormattedMessage id='order-details.return' />
            </Button>
          )}
          <ErrorMessage>{error && <FormattedMessage id={error} />}</ErrorMessage>
          {isLATAM && (
            <VatTaxStatement>
              *<FormattedMessage id='global-mesages.vat-msg' />*
            </VatTaxStatement>
          )}
        </Col>
      </Row>
    </StyledContainer>
  )
}

const StyledContainer = styled(BContainer)`
  h3 {
    font-size: min(28px, 4vw);
    font-weight: normal;
    text-align: left;
    margin: 40px 0 20px;
  }

  .btn-primary {
    background-color: #0f4b8f;
    padding: 10px 30px;
    border-radius: 5px;
    font-weight: bold;
    margin-top: 40px;
  }

  p {
    font-size: 20px;
  }
`

const SubmitButtonWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;

  #async-loader {
    position: absolute;
    left: 49%;
    top: 50%;

    @media (max-width: 992px) {
      left: 47%;
    }
  }
`
const ErrorMessage = styled.p`
  color: red;
`

const VatTaxStatement = styled.p`
  font-weight: 800;
`

export default OrderDetails
