import {
    GET_LEDGER_START,
    GET_LEDGER_SUCCESS,
    GET_LEDGER_FAILURE
} from '../actions/ledgerActionTypes';

const initalState = {
    pointsInfo: [],
    fetching: false,
    error: null,
}


export const ledgerReducer = (state = initalState, action) => {
    switch (action.type) {
        case GET_LEDGER_START :
           return {
                ...state,
                fetching: true,
                error:null
            }
            case GET_LEDGER_SUCCESS :
           return {
                ...state,
                fetching: false,
                error:null,
                pointsInfo: action.payload
            }
            case GET_LEDGER_FAILURE :
           return {
                ...state,
                fetching: false,
                error:action.payload
            }
        default:
            return state
    }

}
