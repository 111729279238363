import React from "react";
import styled from "styled-components";
import moment from "moment";
import { FormattedMessage } from 'react-intl';

const AutoshipCards = ({ runDate, shipTo, quantity, profileId, setSelectedAutoShipInfo }) => {

  let totalQuantity = 0;
  totalQuantity+= quantity

  return (
    <AutoshipCard>
      <input
        type="radio"
        name={'autoship'}
        onChange={() => setSelectedAutoShipInfo({shipTo, autoShipDateParsed: moment(runDate).format("M/DD/YYYY"), profileId, totalQuantity})}
        value={profileId}
      />
      
      <div>
        <h4><FormattedMessage id="autoship-cards.next-run-date" />: { moment(runDate).format("M/DD/YYYY")}</h4>
        <p><FormattedMessage id="autoship-cards.ship-to" />: {shipTo}</p>
        <p><FormattedMessage id="autoship-cards.products" />: {quantity}</p>
      </div>
    </AutoshipCard>
  );
};

const AutoshipCard = styled.div`
  width: 100%;
  background: #f4fafe 0% 0% no-repeat padding-box;
  padding: 15px;
  display: flex;
  flex-direction: row;

  h4 {
    font-size: min(4vw, 22px);
    text-align: left;
    font-weight: bold;
  }

  p {
    font-size: min(3.5vw, 18px);
    text-align: left;
    margin: 0;
  }

  input {
    height: 25px;
    border: 0px;
    width: 25px;
    margin: 5px 15px 0 0;
  }
`;

export default AutoshipCards;
