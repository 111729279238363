import { APP_LOAD_SUCCESS, APP_LOGIN_START, APP_LOGIN_SUCCESS, APP_LOGIN_ERROR, SSO_LOGIN_START, SSO_LOGIN_SUCCESS, SSO_LOGIN_FAILURE, GET_SSO_TOKEN_START, GET_SSO_TOKEN_SUCCESS, GET_SSO_TOKEN_FAILURE, GET_USER_DATA_START, GET_USER_DATA_SUCCESS, GET_USER_DATA_FAILURE, LOGOUT, RESET_APP_STATE, CHANGE_LOCALE, UPDATE_USER_REGION } from '../actions/appActionsTypes'

const initalState = {
  fetching: false,
  error: null,
  isLoggedIn: false,
  token: null,
  user: null,
  userShipCountry: null,
  repId: null,
  lang: null,
  userRegion: null,
}

export const appReducer = (state = initalState, action) => {
  switch (action.type) {
    case APP_LOAD_SUCCESS:
      return {
        ...state,
      }
    case APP_LOGIN_START:
      return {
        ...state,
        error: null,
        fetching: true,
      }
    case APP_LOGIN_SUCCESS:
      return {
        ...state,
        fetching: false,
        isLoggedIn: true,
        token: action.payload.token,
        repId: action.payload.repId,
      }
    case APP_LOGIN_ERROR:
      return {
        ...state,
        error: action.payload,
        fetching: false,
      }
    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
      }
    case SSO_LOGIN_START:
      return {
        ...state,
        error: '',
        bdGuid: action.payload.token,
        fetching: true,
      }
    case SSO_LOGIN_SUCCESS:
      return {
        ...state,
        error: '',
        token: action.payload.token,
        repId: action.payload.repId,
        isLoggedIn: true,
        fetching: false,
      }
    case SSO_LOGIN_FAILURE:
      return {
        ...state,
        error: action.payload,
        fetching: false,
      }
    case GET_SSO_TOKEN_START:
      return {
        ...state,
        fetching: true,
        error: '',
      }
    case GET_SSO_TOKEN_SUCCESS:
      return {
        ...state,
        bdGuid: action.payload,
        fetching: false,
        error: '',
      }
    case GET_SSO_TOKEN_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.payload,
      }

    case GET_USER_DATA_START:
      return {
        ...state,
        fetching: true,
        error: '',
      }
    case GET_USER_DATA_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: '',
        user: action.payload,
        userShipCountry: action.payload.shipCountry,
      }
    case GET_USER_DATA_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.payload,
      }
    case RESET_APP_STATE:
      return {
        ...initalState,
      }
    case CHANGE_LOCALE:
      return {
        ...state,
        lang: action.payload,
      }
    case UPDATE_USER_REGION:
      return {
        ...state,
        userRegion: action.payload,
      }

    default:
      return state
  }
}
