import { APP_LOGIN_START, APP_LOGIN_SUCCESS, APP_LOGIN_ERROR, GET_USER_DATA_START, GET_USER_DATA_SUCCESS, GET_USER_DATA_FAILURE, SSO_LOGIN_START, SSO_LOGIN_SUCCESS, SSO_LOGIN_FAILURE, GET_SSO_TOKEN_START, GET_SSO_TOKEN_SUCCESS, GET_SSO_TOKEN_FAILURE, LOGOUT, RESET_APP_STATE } from './appActionsTypes';
import * as RedemptionActions from './redemptionActionTypes';
import axios from 'axios';
import config from '../../config/env-url';

export const loginAsync = (username, password) => (dispatch) => {
  dispatch({ type: APP_LOGIN_START });
  return axios
    .post(`${config.BASEURL}/Rep/Login`, { username, password })
    .then((res) => {
      const { repId, token } = res.data;
      dispatch({ type: APP_LOGIN_SUCCESS, payload: res.data });
      dispatch(fetchUserData(repId, token));
    })
    .catch((error) => {
      dispatch({ type: APP_LOGIN_ERROR, payload: error });
    });
};

export const fetchUserData = (repId, token) => (dispatch) => {
  dispatch({ type: GET_USER_DATA_START });
  return axios
    .get(`${config.AMBBASEURL}/Profile/${repId}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      dispatch({ type: GET_USER_DATA_SUCCESS, payload: res.data });
    })

    .catch((error) => {
      dispatch({ type: GET_USER_DATA_FAILURE, payload: error });
    });
};

export const SSOLogin = (ssoToken) => (dispatch) => {
  dispatch({ type: SSO_LOGIN_START, payload: { ssoToken } });
  return axios
    .post(`${config.BASEURL}/Rep/SsoLogin`, { ssoToken })
    .then((res) => {
      if (res.data.repId && res.data.token) {
        
        const { repId, token } = res.data;
        dispatch({ type: SSO_LOGIN_SUCCESS, payload: res.data });
        dispatch(fetchUserData(repId, token));
      }
    })
    .catch((error) => {
      dispatch({ type: SSO_LOGIN_FAILURE, payload: error });
    });
};

export const getSSOToken = (repId) => (dispatch, getState) => {
  const { token } = getState().app;
  dispatch({ type: GET_SSO_TOKEN_START });
  return axios
    .get(`${config.BASEURL}/Rep/${repId}/SsoToken`, { headers: { Authorization: `Bearer ${token}` } })
    .then((res) => {
      dispatch({ type: GET_SSO_TOKEN_SUCCESS, payload: res.data.ssoToken });
      return res.data.ssoToken;
    })
    .catch((error) => {
      dispatch({ type: GET_SSO_TOKEN_FAILURE, payload: error });
    });
};

export const logout = (history) => (dispatch) => {
  localStorage.removeItem('token');
  localStorage.removeItem('repId');

  dispatch({ type: LOGOUT });
  dispatch({ type: RESET_APP_STATE });
  dispatch({ type: RedemptionActions.RESET_REDEMPTION_STATE });
  history.push('/login');
};

export const handleRedirect = (site) => (dispatch, getState) => {
  const { user } = getState().app;
  if (user && !isNaN(user.repId)) {
    dispatch(getSSOToken(user.repId))
      .then((res) => {
        handleRedirectLink(site, res);
      })
      .catch((error) => {
        handleRedirectLink(site, null);
        console.error('Error getting token', error);
      });
  }
};

const handleRedirectLink = (site, token) => {
  switch (site) {
    case 'Shop':
      token ? window.open(`http://shopdataeu.zilis.com/users/sso?token=${token}`, '_newtab') : window.open('https://shopus.zilis.com', '_newtab'); // this GUID must be a production GUID as Shopify Login is with Freedom production.
      break;
    case 'Cloud':
      token ? window.open(`https://tools.securefreedom.com/${config.FREEDOMURL}/Account/SeamlessAuthentication/${token}`, '_newtab') : window.open('https://tools.securefreedom.com/Zilis/Account/LogOn', '_newtab');
      break;
    case 'Resources':
      token ? window.open(`https://resources.zilis.com/?autologin_code=vKI6JTZPA88l2xusgD9bcP0ntG8rKZk3`, '_newtab') : window.open('https://resources.zilis.com');
      break;
    default:
      window.open('/');
      break;
  }
};
