/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { loginAsync } from '../../redux/actions/appActions'
import { useToasts } from 'react-toast-notifications'
import { useHistory, useLocation } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import './login.css'

const Login = (props) => {
  const dispatch = useDispatch()
  const { isLoggedIn, token, repId, error } = useSelector((state) => state.app)
  const [ambassadorId, setAmbassadorId] = useState('')
  const [password, setPassword] = useState('')
  const { addToast } = useToasts()
  let history = useHistory()
  var [hasError, setHasError] = useState(false)

  let location = useLocation()
  let { from } = location.state || { from: { pathname: '/dashboard' } }

  const login = (e, ambassadorId, password) => {
    e.preventDefault()
    dispatch(loginAsync(ambassadorId, password))
  }

  useEffect(() => {
    isLoggedIn && handleLoginFinished()
  }, [isLoggedIn])

  useEffect(() => {
    error && handleResponseError()
  }, [error])

  const handleLoginFinished = () => {
    if (isLoggedIn) {
      setAmbassadorId('')
      setPassword('')
      addToast('Logged in successfully', { appearance: 'success', autoDismiss: true })
      localStorage.setItem('token', token)
      localStorage.setItem('repId', repId)
      history.replace(from)
    }
    if (!ambassadorId) {
      setHasError(true)
    }
    if (!password) {
      setHasError(true)
    }
  }
  const handleResponseError = () => {
    setHasError(true)
  }

  return (
    <div>
      <CustomForm
        onSubmit={(e) => {
          login(e, ambassadorId, password)
        }}
      >
        <Row style={{ margin: 0, padding: '10px', backgroundColor: '#e3e3e3' }}>
          <Col xs={12}>
            <h2 style={{ textAlign: 'center', fontWeight: 600, color: '#0f4b8f' }}>Zilis Starpoint Redemption</h2>
          </Col>
        </Row>

        <Row style={{ padding: '50px 30px' }}>
          <Col xs={12} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Row style={{ marginBottom: '20px' }}>
              <Col xs={12}>
                <h3 style={{ textAlign: 'center', fontWeight: 600, color: '#0f4b8f' }}>LOGIN</h3>
              </Col>
            </Row>

            <Form.Group as={Row} controlId='ambassadorId'>
              <Form.Label column xs={12} style={{ textAlign: 'left', fontWeight: 600, color: '#0f4b8f' }}>
                <FormattedMessage id='login.ambassador-id' />
              </Form.Label>
              <Col xs={12}>
                <Form.Control float='center' type='text' name='ambassadorId' value={ambassadorId} onChange={(e) => setAmbassadorId(e.target.value)}></Form.Control>
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column xs={12} style={{ textAlign: 'left', fontWeight: 600, color: '#0f4b8f' }}>
                <FormattedMessage id='login.password' />
              </Form.Label>
              <Col xs={12}>
                <Form.Control type='password' name='password' value={password} onChange={(e) => setPassword(e.target.value)}></Form.Control>
              </Col>
            </Form.Group>

            <Row style={{ marginTop: '20px' }}>
              <Col style={{ textAlign: 'center' }}>
                <LoginBtn type='submit'>
                  <FormattedMessage id='login.login-btn-title' />
                </LoginBtn>
                {hasError && (
                  <p className='form-text text-danger'>
                    * <FormattedMessage id='login.errors.inval-creds-msg' />
                  </p>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </CustomForm>
    </div>
  )
}

export default Login

const CustomForm = styled(Form)`
  max-width: 450px;
  margin: 150px auto;
  text-align: right;
  width: 100%;
  padding: 0;
  border: 2px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 8px 18px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  border-radius: 8px;

  @media (max-width: 594px) {
    width: 90%;
  }
`

const LoginBtn = styled.button`
  width: 80%;
  height: 50px;
  border: none;
  background-color: #0f4b8f;
  color: #fff;
  border-radius: 8px;
  font-size: 20px;
  transition: all 0.3s ease-in-out;

  :hover {
    box-shadow: 0 8px 10px rgba(0, 0, 0, 0.2);
    background-color: #083261;
  }
`
