import React from "react";
import styled from "styled-components";
import headerImageStar from "../assets/StarClub@2x.png";
import starHeaderLogo from "../assets/Group 2482@2x.png";
import { Link } from "react-router-dom";

const Header = ({text}) => {

  return (
    <HeaderContainer>
      <HeaderLogo>
        <Link to="/dashboard"><img alt="Star Club Loyality Program" src={starHeaderLogo} /></Link>
      </HeaderLogo>
      <HeaderTitle>
        <HeaderImage src={headerImageStar} />
        <HeaderReedemText>{text}</HeaderReedemText>
      </HeaderTitle>
    </HeaderContainer>
  );
};

const HeaderReedemText = styled.span`
padding-left: 3px;
vertical-align: bottom;
@media (max-width: 768px) {
  font-size: 24px;
}
`

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  @media (max-width: 768px) {
    width: 85%;
  }
`;
const HeaderImage = styled.img`
  width: 70px;
  @media (max-width: 768px) {
    width: 50px;
  }
`;
const HeaderTitle = styled.div`
  color: #0f4b8f;
  font-size: 34px;
  font-weight: bold;
  @media (max-width: 768px) {
    white-space: nowrap;
    margin-left: 65px;
  }
`;

const HeaderLogo = styled.div`
  img {
    width: 350px;
  }

  @media (max-width: 992px) {
    display: none;
  }
`;

export default Header;
