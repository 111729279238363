import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import starHeaderLogo from '../../assets/Group 2482@2x.png'
import cardStarLogo from '../../assets/StarClub@2x.png'
import clockLogo from '../../assets/icons8-clock@2x.png'
import styled from 'styled-components'
import { Container, Row, Col } from 'react-bootstrap'
import StarpointScale from './StarpointScale'
import { Link, useHistory } from 'react-router-dom'
import { fetchAutoShipData, fetchPointsData } from '../../redux/actions/redemptionActions'
import { handleRedirect } from '../../redux/actions/appActions'
import moment from 'moment'
import { FormattedMessage, FormattedDate, FormattedNumber } from 'react-intl'
import SpinnerLoader from '../SpinnerLoader/SpinnerLoader'
import Modal from '../global-components/Modal'

const Dashboard = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { token, repId, user } = useSelector((state) => state.app)
  const { availableAmount, lifetimeAmount, autoShipProfiles, totalVolume, pointsValid, maxMonthlyRedemptionAmount, balanceRealTime } = useSelector((state) => state.redemption)
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    dispatch(fetchPointsData(repId, token))
    dispatch(fetchAutoShipData(repId, token))
  }, [repId, token])

  const autoShipDate = autoShipProfiles.length > 0 && moment(autoShipProfiles[0].nextShipDate)
  const ssoToCloud = () => {
    dispatch(handleRedirect('Cloud'))
  }

  const handleValidatePoints = () => {
    if (!pointsValid) {
      setShowModal(true)
    } else {
      history.push('redeem')
    }
  }

  const toggleModal = (modalState) => {
    setShowModal(modalState)
  }

  return (
    <OuterContainer>
      <Modal showModal={showModal} toggleModal={toggleModal} navigateTo={'redeem'} message={'dashboard.max-startpoint-modal'} />
      <DashContainer>
        <HeaderContainer>
          <img alt='Star Club Loyalty Program' src={starHeaderLogo} />
        </HeaderContainer>

        <AutoshipHeaderText>
          {user && user.firstName !== '' && (
            <GreetingText>
              <FormattedMessage id='dashboard.welcome-msg' values={{ name: user.firstName }} />
            </GreetingText>
          )}
          {autoShipDate && (
            <div>
              <p>
                <FormattedMessage id='dashboard.bv-total-msg' values={{ bv: <FormattedNumber value={totalVolume}></FormattedNumber>, date: autoShipDate && <FormattedDate value={autoShipDate} month='long' day='numeric'></FormattedDate> }} />
              </p>
              <p>
                <FormattedMessage id='dashboard.next-run-date-msg' values={{ date: autoShipDate && <FormattedDate value={autoShipDate} month='long' day='numeric'></FormattedDate> }} />
              </p>
            </div>
          )}
          {!autoShipDate && (
            <p style={{ margin: '20px 20px 80px' }}>
              <FormattedMessage id='dashboard.no-autoship-msg' values={{ a: (chunks) => <ExternalLink onClick={ssoToCloud}>{chunks}</ExternalLink> }} />
            </p>
          )}
        </AutoshipHeaderText>

        {autoShipDate ? (
          <CustomContainer fluid>
            <Row>
              {/* Available earnings card */}
              <Col lg={6} xs={12}>
                <Card>
                  <Row style={{ justifyContent: 'center', alignItems: 'center', minHeight: '222px' }}>
                    <Col md={4} xs={12}>
                      <img src={cardStarLogo} alt='StarPoint Logo' />
                    </Col>
                    <ColWrapper md={8} xs={12}>
                      <div className='content'>
                        <h3>
                          <FormattedMessage id='dashboard.available-earnings' />
                        </h3>
                        <p style={{ fontSize: 'clamp(18px,1.2vw,22px)' }}>
                          <FormattedMessage id='dashboard.starpoints-available-msg' values={{ amount: availableAmount }} />
                        </p>
                        <p style={{ fontSize: '20px' }}>
                          <FormattedMessage id='dashboard.realtime-starpoint' values={{ balance: balanceRealTime }} />
                        </p>
                        <p style={{ fontSize: '12px' }}>
                          <FormattedMessage id='dashboard.starpoint-max-points' values={{ maxAmount: maxMonthlyRedemptionAmount }} />
                        </p>
                        <span
                          style={{ cursor: 'pointer', textDecoration: 'underline', color: 'blue' }}
                          to='redeem'
                          onClick={() => {
                            handleValidatePoints()
                          }}
                        >
                          <FormattedMessage id='dashboard.redeem' />
                        </span>
                      </div>
                    </ColWrapper>
                  </Row>
                </Card>
              </Col>

              {/* Star Point history card */}
              <Col lg={6} xs={12}>
                <Card>
                  <Row style={{ justifyContent: 'center', alignItems: 'center', minHeight: '222px' }}>
                    <Col md={4} xs={12}>
                      <img src={clockLogo} alt='Clock' />
                    </Col>
                    <Col md={8} xs={12}>
                      <div className='content'>
                        <h3>
                          <FormattedMessage id='dashboard.starpoint-history' />
                        </h3>
                        <p style={{ fontSize: '22px' }}>
                          <FormattedMessage id='dashboard.starpoints-earned' values={{ amount: lifetimeAmount }} />
                        </p>
                        <Link to='history'>
                          <FormattedMessage id='dashboard.view' />
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </CustomContainer>
        ) : (
          <Row>
            <SpinnerLoader width='35px' height='35px' className='d-flex mx-auto ' />
          </Row>
        )}
        <Container fluid>
          <Row className='mt-5'>
            <Col lg={6} xs={12} className='pl-0 pr-0 '>
              <DashReminderTextLeft>
                <FormattedMessage id='dashboard.update-autoship-banner-1' />
              </DashReminderTextLeft>
            </Col>
            <Col lg={6} xs={12}>
              <DashReminderTextRight>
                <span>
                  <FormattedMessage
                    id='dashboard.update-autoship-banner-2'
                    values={{
                      a: (chunks) => (
                        <ExternalLink style={{ textDecoration: 'underline' }} onClick={ssoToCloud}>
                          {chunks}
                        </ExternalLink>
                      ),
                    }}
                  />
                </span>
              </DashReminderTextRight>
            </Col>
          </Row>
        </Container>
        <StarpointScale bv={totalVolume} />
      </DashContainer>
    </OuterContainer>
  )
}

const CustomContainer = styled(Container)`
  padding: 0;

  @media (max-width: 595px) {
    padding: 15px;
  }
`

const ExternalLink = styled.div`
  color: blue;
  display: inline-block;
  cursor: pointer;
`

const DashReminderTextRight = styled.div`
  display: flex;
  font-weight: bold;
  font-size: 30px;
  text-align: center;
  align-items: center;
  justify-content: center;
  height: 100%;
`

const DashReminderTextLeft = styled.div`
  color: #0f4b8f;
  font-weight: bold;
  font-size: 38px;
  text-align: center;
  border-right: 5px solid #febc1b;

  @media (max-width: 992px) {
    border-right: none;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 5px solid #febc1b;
  }
`

const Card = styled.div`
  background: #f4fafe 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  margin: 40px 0 80px;

  img {
    padding: 15px;
    width: 100%;
    max-width: 170px;
  }

  .content {
    padding: 15px;
    text-align: left;

    h3 {
      color: #0f4b8f;
      text-align: left;
      margin: 0;
      font-size: 24px;
    }

    p {
      margin: 10px 0;
      font-weight: bold;
    }

    a {
      text-decoration: underline;
    }
  }

  @media (max-width: 992px) {
    margin: 20px 0;
  }

  @media (max-width: 768px) {
    h3,
    .content {
      text-align: center !important;
    }
  }
`

const OuterContainer = styled(Container)`
  @media (max-width: 768px) {
    padding: 0 5px;
  }
`

const DashContainer = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  font-family: Proxima Nova;
`

const HeaderContainer = styled.div`
  display: flex;

  img {
    max-width: 350px;
    width: 100%;
  }

  @media (max-width: 992px) {
    justify-content: center;
    align-items: center;
    width: 100%;
  }
`

const AutoshipHeaderText = styled.div`
  text-align: left;

  p {
    font-size: 24px;
    margin: 10px;

    @media (max-width: 768px) {
      font-size: 20px;
    }
  }
`

const GreetingText = styled.p`
  font-size: 30px;
  font-weight: bold;
  margin: 10px;

  @media (max-width: 768px) {
    font-size: 24px;
  }
`

const ColWrapper = styled(Col)`
  /* padding: 0; */

  @media (max-width: 992px) {
    padding: 2%;
  }
`


export default Dashboard
