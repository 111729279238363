import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import commonData from '../config/common-data'

const useRegionSelector = () => {
  const { userRegion } = useSelector((state) => state.app)
  const [isUSA, setIsUSA] = useState(false)
  const [isEU, setIsEU] = useState(false)
  const [isLATAM, setIsLATAM] = useState(false)

  useEffect(() => {
    if (userRegion === commonData.regions.usa) {
      setIsUSA(true)
    } else if (userRegion === commonData.regions.latam) {
      setIsLATAM(true)
    } else if (userRegion === commonData.regions.eu) {
      setIsEU(true)
    }
  }, [userRegion])

  return { isUSA: isUSA, isLATAM: isLATAM, isEU: isEU }
}

export default useRegionSelector
