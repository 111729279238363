import React, { useState } from 'react';
import styled from 'styled-components';
import { FormControl, Button } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';

export const VolumeFilter = ({ handleFilterByVolume }) => {
  const intl = useIntl();

  const [minVolume, setMinVolume] = useState('');
  const [maxVolume, setMaxVolume] = useState('');

  return (
    <VolumeFilterContainer>
      <InputContainer>
        <InputBV value={minVolume} onChange={(e) => setMinVolume(e.target.value)} aria-label="Small" aria-describedby="inputGroup-sizing-sm" width="25%" placeholder={intl.formatMessage({ id: 'form-labels.minimum-bv' })} />
        <span style={{ padding: '8px' }}>
          <FormattedMessage id="form-labels.to" />
        </span>
        <InputBV value={maxVolume} onChange={(e) => setMaxVolume(e.target.value)} aria-label="Small" aria-describedby="inputGroup-sizing-sm" width="25%" placeholder={intl.formatMessage({ id: 'form-labels.maximum-bv' })} />
        <StyledButton onClick={() => handleFilterByVolume(minVolume, maxVolume)}>
          <FormattedMessage id="form-labels.go" />
        </StyledButton>
      </InputContainer>
    </VolumeFilterContainer>
  );
};

const InputContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  padding: 30px;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const InputBV = styled(FormControl)`
  width: 15%;
  border-radius: 10px;
  @media (max-width: 768px) {
    width: 85%;
  }
`;

const StyledButton = styled(Button)`
  background-color: #0f4b8f;
  text-transform: uppercase;
  margin-left: 25px;
`;

const VolumeFilterContainer = styled.div`
  background: #f7f7f7 0% 0% no-repeat padding-box;
  height: 100px;

  @media (max-width: 768px) {
    height: auto;
    margin-top: 10px;
  }
`;
