/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { Button } from 'react-bootstrap'
import { faInfoCircle, faSortDown, faSortUp } from '@fortawesome/fontawesome-free-solid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormattedMessage } from 'react-intl'
import * as RedemptionActions from '../../redux/actions/redemptionActionTypes'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'

const RewardCard = ({ handleUpdatePointsUsed, productImg, availablePoints, reachedPointsLimit, setReachedPointsLimit, setIsCartEmpty, setGiftProducts, giftProducts, product, totalQuantity, calculatedPoints, setCalculatedPoints, ...props }) => {
  const dispatch = useDispatch()
  const { cart, starShipProducts, pointsValid } = useSelector((state) => state.redemption)
  const [isEnoughPoints, setIsEnoughPoints] = useState(false)
  const [limitReached, setLimitReached] = useState(false)

  useEffect(() => {
    setLimitReached(product.quantity === product.maxQty)
  }, [product.quantity])

  useEffect(() => {
    setIsEnoughPoints(calculatedPoints >= product.points && availablePoints !== 0)
  }, [product.points, calculatedPoints, availablePoints])

  useEffect(() => {
    if (totalQuantity === 0) {
      setIsCartEmpty(true)
    } else {
      setIsCartEmpty(false)
    }
  }, [totalQuantity, product])

  const handleQuantityChange = (operation, product) => {
    if (calculatedPoints < product.points && operation !== -1) {
      setReachedPointsLimit(true)
      return
    }
    setReachedPointsLimit(false)
    const index = starShipProducts.indexOf(product)
    const giftProductsCloned = [...starShipProducts]

    if (operation !== -1 && cart.hasOwnProperty(product.productSku) && limitReached) {
      return
    } else {
      if (!giftProductsCloned[index].quantity) {
        giftProductsCloned[index].quantity = 1
        setCalculatedPoints((prevState) => (prevState -= product.points))
        updateSelectedItemState(product, 'add')
        handleUpdatePointsUsed('add', product.points)
      } else if (giftProductsCloned[index].quantity === 1 && operation === -1) {
        giftProductsCloned[index].quantity = 0
        setCalculatedPoints((prevState) => (prevState += product.points))
        updateSelectedItemState(product, 'sub')
        handleUpdatePointsUsed('sub', product.points)
      } else {
        giftProductsCloned[index].quantity += operation
        if (operation === -1) {
          setCalculatedPoints((prevState) => (prevState += product.points))
          updateSelectedItemState(product, 'sub')
          handleUpdatePointsUsed('sub', product.points)
        }
        if (operation === 1) {
          setCalculatedPoints((prevState) => (prevState -= product.points))
          updateSelectedItemState(product, 'add')
          handleUpdatePointsUsed('add', product.points)
        }
      }
      setReachedPointsLimit()
    }
  }

  const updateSelectedItemState = (product, operation) => {
    var prod = {}

    if (operation === 'add') {
      prod = { [product.productSku]: cart[product.productSku] ? (cart[product.productSku] += 1) : 1 }
    } else {
      if (cart.hasOwnProperty([product.productSku]) && cart[product.productSku] === 1) {
        delete cart[product.productSku]
        prod = cart
      } else {
        prod = { [product.productSku]: (cart[product.productSku] -= 1) }
      }
    }
    const newState = Object.assign(cart, prod)
    dispatch({ type: RedemptionActions.UPDATE_CART, payload: newState })
  }

  const renderTooltip = (props) => (
    <Tooltip id='button-tooltip' {...props}>
      <FormattedMessage id={'reward-card.already-redeemed-msg'} values={{ value: product.dateRestriction }} />
    </Tooltip>
  )

  return (
    <RewardCardContainer>
      <ItemContainer>
        <ProductImageContainer src={productImg} />
        <DescriptionContainer>
          <StarPointsInfoContainer>
            {product.points} <FormattedMessage id='reward-card.star-points' />
          </StarPointsInfoContainer>
          <ProductNameContainer style={{ fontSize: '14px', color: '#000000', textAlign: 'left' }}>{product.description}</ProductNameContainer>
          {<LimitMessage>Limit ({product.maxQty})</LimitMessage>}
        </DescriptionContainer>
      </ItemContainer>
      {!product.quantity ? (
        <InitalSelectionView>
          <StyledButton onClick={() => (!pointsValid ? props.toggleModal(true) : handleQuantityChange(1, product))} disabled={reachedPointsLimit || !isEnoughPoints || product.hasRedeemed}>
            <FormattedMessage id={isEnoughPoints ? (product.hasRedeemed ? 'Not Available' : 'reward-card.add-to-cart') : 'reward-card.need-points'} />
          </StyledButton>
          {product.hasRedeemed && (
            <OverlayTrigger placement='bottom' delay={{ show: 100, hide: 400 }} overlay={renderTooltip}>
              <FontAwesomeIcon style={{ fontSize: '24px', cursor: 'pointer', color: '#0F4B8F', marginTop: '10px' }} icon={faInfoCircle} />
            </OverlayTrigger>
          )}
        </InitalSelectionView>
      ) : (
        <SelectionContainer>
          <EditQuantityContainer>
            <FontAwesomeIcon
              style={{
                fontSize: '40px',
                cursor: limitReached || !isEnoughPoints ? 'not-allowed' : 'pointer',
                color: limitReached || !isEnoughPoints ? '#808080' : '#0F4B8F',
              }}
              icon={faSortUp}
              onClick={() => handleQuantityChange(1, product)}
            />
            <span style={{ textAlign: 'left' }}>{product.quantity}</span>
            <FontAwesomeIcon style={{ fontSize: '40px', cursor: 'pointer', color: '#0F4B8F' }} icon={faSortDown} onClick={() => handleQuantityChange(-1, product)} />
          </EditQuantityContainer>
          <div className='limit-reached-txt'>{limitReached && <LimitReachedMessage>Limit Reached</LimitReachedMessage>}</div>
        </SelectionContainer>
      )}
    </RewardCardContainer>
  )
}

const EditQuantityContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50px;
  height: 50px;
  margin-bottom: 25px;
  user-select: none;
`
const ProductImageContainer = styled.img`
  width: 150px;
  height: 140px;
  @media (max-width: 768px) {
    height: 30%;
    width: 30%;
  }
`

const ProductNameContainer = styled.div`
  font-size: 14px;
  color: #000;
  text-align: left;
  white-space: no-wrap;
  white-space: normal;
  @media (max-width: 768px) {
    fontsize: 8px;
    white-space: normal;
  }
`

const DescriptionContainer = styled.div`
  padding-left: 25px;
  white-space: nowrap;
  @media (max-width: 768px) {
    min-width: 42vw;
  }
`

const RewardCardContainer = styled.div`
  min-height: 156px;
  background: #f4fafe 0% 0% no-repeat padding-box;
  opacity: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 25px 45px;
  margin-bottom: 10px;
  width: 100%;
  @media (max-width: 768px) {
    width: 100%;
    height: 100px;
    justify-content: center;
    flex: 1 0 auto;
  }
`

const StarPointsInfoContainer = styled.div`
  font-size: 25px;
  color: #0f4b8f;
  text-align: left;
  font-weight: bold;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`

const SelectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 170px;
  position: relative;

  .limit-reached-txt {
    position: absolute;
    bottom: 78px;
    user-select: none;
  }
`

const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1 1 0px;
`

const LimitMessage = styled.p`
  color: #0f4b8f;
  text-align: left;
  font-weight: bold;
`
const LimitReachedMessage = styled.p`
  color: red;
  text-align: left;
  font-weight: bold;
  padding: 0;
  margin: 0;
`

const StyledButton = styled(Button)`
  background-color: '#0f4b8f';
  border-radius: 10px;
  height: 50px;
  width: 173px;
  white-space: nowrap;
  flex-grow: none;
  cursor: ${(props) => (props.disabled ? 'not-allowed;' : 'default;')};
  @media (max-width: 768px) {
    width: 150px !important;
    font-size: 12px;
    margin-left: 50px;
    height: 40px;
  }
`

const InitalSelectionView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export default RewardCard
