const environment = process.env.REACT_APP_ENV || 'development'

const handleSetAppEnvIdentityURL = () => {
  switch (environment) {
    case 'development':
      return 'https://identityapi-test-zilis.azurewebsites.net'
    case 'test':
      return 'https://identityapi-test-zilis.azurewebsites.net'
    case 'production':
      return 'https://identityapi-zilis.azurewebsites.net'
    default:
      return 'https://identityapi-test-zilis.azurewebsites.net'
  }
}

const handleSetAppEnvAmbassadorURL = () => {
  switch (environment) {
    case 'development':
      return 'https://ambassadorapi-test-zilis.azurewebsites.net'
    case 'test':
      return 'https://ambassadorapi-test-zilis.azurewebsites.net'
    case 'production':
      return 'https://ambassadorapi-zilis.azurewebsites.net'
    default:
      return 'https://ambassadorapi-test-zilis.azurewebsites.net'
  }
}

const handleSetLedgerURL = () => {
  switch (environment) {
    case 'development':
      return 'https://orderapi-test-zilis.azurewebsites.net'
    case 'test':
      return 'https://orderapi-test-zilis.azurewebsites.net'
    case 'production':
      return 'https://orderapi-zilis.azurewebsites.net'
    default:
      return 'https://orderapi-test-zilis.azurewebsites.net'
  }
}

const handleSetEnvFreedomURL = () => {
  switch (environment) {
    case 'development':
      return 'zilisSandbox'
    case 'test':
      return 'zilisSandbox'
    case 'production':
      return 'zilis'
    default:
      return 'zilisSandbox'
  }
}

var config = {
  BASEURL: handleSetAppEnvIdentityURL(),
  AMBBASEURL: handleSetAppEnvAmbassadorURL(),
  FREEDOMURL: handleSetEnvFreedomURL(),
  LEDGERURL: handleSetLedgerURL(),
}

export default config
