import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

/**
 *
 * @param {Sucess | Alert | Warn} type
 * @param {string} title
 * @param {string} message
 *
 */

const MaintenanceBanner = ({ type, title, message }) => {
  const [messageType, setMessageType] = useState('')

  useEffect(() => {
    if (type) {
      switch (type) {
        case 'Success':
          setMessageType('#d1e7dd')
          break
        case 'Alert':
          setMessageType('#fff3cd')
          break
        case 'Warn':
          setMessageType('#f8d7da')
          break
        default:
          setMessageType('#cfe2ff')
      }
    }
  }, [type])

  return (
    <BannerWrapper color={messageType}>
      <div className='banner-heading'>
        <h1 className='banner-title'>{title}</h1>
      </div>
      <div className='banner-message'>
        <p>{message}</p>
      </div>
    </BannerWrapper>
  )
}

export default MaintenanceBanner

const BannerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px 2%;
  background-color: ${(props) => (props.color ? props.color : '#cfe2ff')};

  .banner-heading {
    text-align: center;
    .banner-title {
      font-size: 22px;
    }
  }

  .banner-message {
    text-align: center;
    p {
      font-size: 18px;
    }
  }
`
