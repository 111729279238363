import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

const Modal = (props) => {
  const history = useHistory();
  const [modalState, setModalState] = useState(true);

  useEffect(() => {
    setModalState(props.showModal);
  }, [props.showModal]);

  const closeToggleModal = () => {
    setModalState(!modalState);
    props.toggleModal(!modalState);
    props.navigateTo && history.push(props.navigateTo);
  };

  return (
    <Overlay state={modalState}>
      <ModalWrapper>
        <CloseButton
          onClick={() => {
            closeToggleModal();
          }}
        >
          X
        </CloseButton>
        <ModalBodyText>
          <FormattedMessage id={props.message} />
        </ModalBodyText>
      </ModalWrapper>
    </Overlay>
  );
};

export default Modal;

const Overlay = styled.div`
  display: ${(props) => (props.state ? 'flex' : 'none')};
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
`;

const ModalWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  width: 60%;
  min-height: 25%;
  max-width: 895px;
  padding: 2% 3%;

  @media (max-width: 595px) {
    width: 95%;
  }
`;

const ModalBodyText = styled.p`
  font-size: 24px;
  color: #0f4b8f;
  font-weight: 800;
  text-align: center;
  margin-top: 40px;
`;

const CloseButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  width: 36px;
  height: 36px;
  font-size: 30px;
  color: #0f4b8f;
  font-weight: 700;
  text-align: center;
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
`;
