import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navbar, Container, Nav } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import headerLogo from '../../assets/Zilis_Logo_2021.png';
import styled from 'styled-components';
import LanguageMenu from './LanguageMenu';
import Profile from './Profile';
import { device } from '../../config/device-sizes';
import { logout } from '../../redux/actions/appActions';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { subNavLinks } from './LinkData';

const Header = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isMobile = useMediaQuery({ query: '(max-width: 991px)' });
  const { isLoggedIn } = useSelector((state) => state.app);
  const { addToast } = useToasts();

  const logoutApp = () => {
    dispatch(logout(history));
    history.push('/login');
    addToast(`You have been logged out.`, { appearance: 'success', autoDismiss: true });
  };

  return (
    <StyledNavbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand href="#home">
          <img src={headerLogo} height="65px" alt="" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav className="me-auto" style={{ alignItems: 'center' }}>
            <ProfileLangContainer>
              <LanguageMenu bootStrapApp={props.bootStrapApp} />
              {isLoggedIn && (
                <ProfileLogoutWrapper>
                  <Profile />
                  <MobileLinkWrapper onClick={logoutApp}>
                    <FormattedMessage id="header.links.logout"></FormattedMessage>
                  </MobileLinkWrapper>
                </ProfileLogoutWrapper>
              )}
            </ProfileLangContainer>
            {isLoggedIn &&
              isMobile &&
              subNavLinks.map((link, index) => {
                return (
                  <MobileLinkWrapper key={index}>
                    <Link style={{ color: '#000' }} to={link.link}>
                      <FormattedMessage id={link.tanslateId}></FormattedMessage>
                    </Link>
                  </MobileLinkWrapper>
                );
              })}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </StyledNavbar>
  );
};

const StyledNavbar = styled(Navbar)`
  background-color: white !important;
  border-bottom: 3px solid #0f4b8f;
  margin-bottom: 30px;
`;

const ProfileLogoutWrapper = styled.div`
  margin: 0 3%;
  order: 2;

  @media ${device.tablet} {
    width: 100%;
    order: 1;
  }
`;

const MobileLinkWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease;

  :hover {
    cursor: pointer;
  }

  @media ${device.tablet} {
    height: 24px;
    padding: 30px 40px;
    background-color: #e3e3e3;
    font-size: 20px;
    width: 100%;

    :hover {
      background-color: #c0c0c0;
    }
  }
`;

const ProfileLangContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: center;

  @media ${device.tablet} {
    flex-direction: column;
    align-items: center;
  }
`;

export default Header;
