import { combineReducers } from 'redux';
import { appReducer } from '../reducers/appReducer';
import { ledgerReducer } from '../reducers/ledgerReducer';
import { redemptionReducer } from '../reducers/redemptionReducer';


export default combineReducers({
        app: appReducer,
        ledger:ledgerReducer,
        redemption: redemptionReducer
});



