/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useCallback, useState } from 'react'
import { Switch, Route, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { fetchUserData, SSOLogin } from './redux/actions/appActions'
import commonData from './config/common-data'
import { useToasts } from 'react-toast-notifications'
import PrivateRoute from './components/global-components/PrivateRoute'
import URLHelper from './middleware/URLHelperClass'
import Login from './components/Login/Login'
import Ledger from './components/Ledger/Ledger'
import Dashboard from './components/Dashboard/Dashboard'
import ReedemFunnel from './components/Funnel/ReedemFunnel'
import OrderDetails from './components/OrderDetails/OrderDetails'
import Header from './components/Header/Header'
import MobileFooter from './components/Footer/MobileFooter'
import DesktopLinks from './components/Header/DesktopLinks'
import styled from 'styled-components'
import { APP_LOAD_SUCCESS, APP_LOGIN_SUCCESS, UPDATE_USER_REGION } from './redux/actions/appActionsTypes'
import { UPDATE_POINTS_VALID } from './redux/actions/redemptionActionTypes'
import Interceptor from './middleware/interceptor'
import { logout } from './redux/actions/appActions'
import { Alert, Container } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import './app.css'
import MaintenanceBanner from './components/global-components/MaintenanceBanner'

function App(props) {
  const urlHelper = new URLHelper()
  const dispatch = useDispatch()
  const history = useHistory()
  Interceptor.interceptor(dispatch, history)
  const { user, userShipCountry } = useSelector((state) => state.app)
  const { balanceRealTime, balance } = useSelector((state) => state.redemption)
  var SSOToken = null
  const { addToast } = useToasts()
  const [isMobile, setIsMobile] = useState(false)

  const handleToastMessage = useCallback(
    (type) => {
      switch (type) {
        case 'successfullLogin':
          addToast(`Welcome Back ${user.firstName + ' ' + user.lastName}!`, { appearance: 'success', autoDismiss: true })
          break
        case 'ssoMessage':
          addToast('Attempting auto-signin', { appearance: 'success', autoDismiss: true })
          break
        default:
          addToast(`StarPoint App`, { appearance: 'success', autoDismiss: true })
          break
      }
    },
    [user]
  )

  useEffect(() => {
    SSOToken = urlHelper.handleGetURLQueryValue('rt')
    if (SSOToken !== false && SSOToken !== null) {
      console.log(SSOToken)
      dispatch(SSOLogin(SSOToken))
      handleToastMessage('ssoMessage')
      history.replace('/dashboard')
    }
    handleIsMobile()
  }, [])

  useEffect(() => {
    const repId = localStorage.getItem('repId')
    const token = localStorage.getItem('token')

    dispatch({ type: APP_LOAD_SUCCESS })

    if (token && token !== 'undefined') {
      const authPayload = { token, repId }
      dispatch({ type: APP_LOGIN_SUCCESS, payload: authPayload })
      dispatch(fetchUserData(repId, token))
    } else {
      dispatch(logout(history))
    }
  }, [])

  useEffect(() => {
    if (balanceRealTime !== null && balance !== null) validatePoints()
  }, [balanceRealTime, balance])

  useEffect(() => {
    if (userShipCountry) {
      let userRegion = null
      if (commonData.euCountries.includes(userShipCountry)) {
        userRegion = commonData.regions.eu
      } else if (commonData.latamCountries.includes(userShipCountry)) {
        userRegion = commonData.regions.latam
      } else if (userShipCountry === commonData.regions.usa) {
        userRegion = commonData.regions.usa
      }
      userRegion && dispatch({ type: UPDATE_USER_REGION, payload: userRegion })
    }
  }, [userShipCountry])

  const validatePoints = () => {
    balanceRealTime === balance ? dispatch({ type: UPDATE_POINTS_VALID, payload: true }) : dispatch({ type: UPDATE_POINTS_VALID, payload: false })
  }

  const handleIsMobile = () => {
    setIsMobile(window.innerWidth <= 768)
  }

  window.addEventListener('resize', () => {
    setIsMobile(window.innerWidth <= 768)
  })

  return (
    <MainAppContainer>
      {false && <MaintenanceBanner type={'Warn'} message={'Sorry for the inconvenience, but Starpoint will be under maintenance on 12/08/2022 from 8am – 6pm CST. If you require immediate assistance, please call Ambassador Support Toll Free (844) 385-8724'} />}
      <Header bootStrapApp={props.bootStrapApp} />
      <DesktopLinks />
      {/* <Container>
          <Alert variant="success"><FormattedMessage id="alert-message" /></Alert>
      </Container> */}
      <AppWrapper isMobile={isMobile} className={`${isMobile ? 'mobile' : ''}`}>
        <Switch>
          <Route exact path='/'>
            <Dashboard />
          </Route>
          <PrivateRoute path='/dashboard'>
            <Dashboard />
          </PrivateRoute>
          <PrivateRoute path='/history'>
            <Ledger />
          </PrivateRoute>
          <PrivateRoute path='/redeem'>
            <ReedemFunnel />
          </PrivateRoute>
          <Route path='/login'>
            <Login />
          </Route>
          <PrivateRoute path='/order-details'>
            <OrderDetails />
          </PrivateRoute>
          <Route path='*'>
            <Dashboard />
          </Route>
        </Switch>
      </AppWrapper>
      <MobileFooter />
    </MainAppContainer>
  )
}

export default App

const MainAppContainer = styled.div`
  text-align: center;
  position: relative;
  height: 100%;
`

const AppWrapper = styled.div`
  text-align: center;
  min-height: calc(100% - 351px);

  &.mobile {
    min-height: calc(100% - 251px);
  }
`
//Setting min height and passing props addresses both the Sticky footer desktop and sticky mobile footer.
