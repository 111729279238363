import React from 'react';
import { Form } from 'react-bootstrap';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

const SortFilter = ({ handleSortData, categories, handleCategorySort, sizes, handleSizeSort }) => {
  return (
    <SortFilterContainer>
      <FilterSelect>
        <Form.Label>
          <FormattedMessage id="form-labels.category" />:
        </Form.Label>

        <Form.Group>
          <Form.Control as="select" style={{ width: '100%', borderRadius: '10px' }} onChange={(e) => handleCategorySort(e.target.value)}>
            <option key="All Products">All Products</option>
            {categories.map((option) => (
              <option key={option}>{option}</option>
            ))}
          </Form.Control>
        </Form.Group>
      </FilterSelect>

      <FilterSelect>
        <Form.Label>
          <FormattedMessage id="form-labels.sort-by" />:
        </Form.Label>

        <Form.Group>
          <Form.Control as="select" style={{ width: '100%', borderRadius: '10px' }} onChange={(e) => handleSortData(e.target.value)}>
            <FormattedMessage id="form-labels.lowest-to-highest" key="lowest-to-highest">
              {(message) => <option value="Lowest To Highest">{message}</option>}
            </FormattedMessage>
            <FormattedMessage id="form-labels.highest-to-lowest" key="highest-to-lowest">
              {(message) => <option value="Highest To Lowest">{message}</option>}
            </FormattedMessage>
          </Form.Control>
        </Form.Group>
      </FilterSelect>

      {sizes && sizes.length > 0 && (
        <FilterSelect>
          <Form.Label>
            <FormattedMessage id="form-labels.size" />:
          </Form.Label>

          <Form.Group>
            <Form.Control as="select" style={{ width: '100%', borderRadius: '10px' }} onChange={(e) => handleSizeSort(e.target.value)}>
              {sizes.map((option) => (
                <FormattedMessage id={`form-labels.sizes.${option.toLowerCase()}`} key={option}>
                  {(message) => <option value={option}>{message}</option>}
                </FormattedMessage>
              ))}
            </Form.Control>
          </Form.Group>
        </FilterSelect>
      )}
    </SortFilterContainer>
  );
};

const FilterSelect = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;

  label {
    color: #0f4b8f;
    font-weight: bold;
    margin: 0;
    padding: 5px 10px;
  }

  .form-group {
    margin: 0;
  }

  @media (max-width: 768px) {
    align-items: start;
    flex-direction: column;
  }
`;

const SortFilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 5px;
`;

export default SortFilter;
