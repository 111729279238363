import React from "react";
import ReactDOM from "react-dom";
import "./css/index.css";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
//Redux
import { Provider } from "react-redux";
import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import rootReducer from "./redux/reducers/index";
//Redux
import { BrowserRouter as Router } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
//Translations
import { IntlProvider } from "react-intl";
import flatten from "flat";

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

const loadLocaleData = (locale) => {
  switch (locale) {
    case "en-US":
      return import("./lang/en-US.json");
    case "es-ES":
      return import("./lang/es-ES.json");
    default:
      return import("./lang/en-US.json");
  }
};

function MainApp(props) {
  return (
    <Router>
      <ToastProvider>
        <Provider store={store}>
          <IntlProvider
            locale={props.locale}
            defaultLocale="en-US"
            messages={props.messages}
          >
            <App defaultLocale={props.locale} bootStrapApp={bootStrapApp} />
          </IntlProvider>
        </Provider>
      </ToastProvider>
    </Router>
  );
}

async function bootStrapApp(locale) {
  const { messages } = await loadLocaleData(locale);
  ReactDOM.render(
    <MainApp
      locale={locale}
      messages={flatten(messages)}
      bootStrapApp={bootStrapApp}
    />,
    document.getElementById("root")
  );
}

bootStrapApp("en-US"); // load the app and default language to english
