import React from "react";
import styled from "styled-components";
import { FormattedMessage } from 'react-intl';

const MobileFooter = () => {
  return (
    <CardContainer>
      <div> <FormattedMessage id="footer.copyright" /></div>
      <div>
        <a href="https://zilis.com/contact-us/" target="_blank" rel="noreferrer"><FormattedMessage id="footer.contact-us" /></a> | <a href="https://zilis.com/wp-content/uploads/2020/06/Privacy_Policies_06_25_20.pdf" target="_blank" rel="noreferrer"><FormattedMessage id="footer.privacy-policy" /></a>
      </div>
    </CardContainer>
  );
};

const CardContainer = styled.div`
  background-color: #f7f7f8;
  display: flex;
  flex-direction: column;
  padding: 22px 0;
  margin-top: 40px;
`;


export default MobileFooter
