/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { CHANGE_LOCALE } from "../../redux/actions/appActionsTypes";
import { device } from "../../config/device-sizes";

const LanguageMenu = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: CHANGE_LOCALE, payload: "en-US" });
  }, []);

  const updateLang = (lang) => {
    props.bootStrapApp(lang);
    dispatch({ type: CHANGE_LOCALE, payload: lang });
  };

  return (
    <LanugageWrapper>
      <LanguageSelector
        onChange={(e) => {
          updateLang(e.target.value);
        }}
      >
        <option value="en-US">English</option>
        <option value="es-ES">Spanish</option>
      </LanguageSelector>
    </LanugageWrapper>
  );
};

export default LanguageMenu;

const LanugageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: baseline;
  order: 1;

  @media ${device.tablet} {
    margin-top: 3%;
    order: 1;
  }
`;

const LanguageSelector = styled.select`
  width: 120px;
  height: 40px;
  border-radius: 5px;
  background-color: #f8f9fa;
  margin: 0 30px;
`;
